import Vue from 'vue'
import './plugins/axios'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/flashmessage.js'
import './plugins/vuelidate.js'

import TextEmphasizes from "@/components/TextEmphasizes.vue";
import WorkspaceSelector from "@/components/WorkspaceSelector.vue";
import LocationScanner from "@/components/LocationScanner.vue";
import ProductScanner from "@/components/ProductScanner.vue";
import PackingMaterialScanner from "@/components/PackingMaterialScanner.vue";
import MainMenu from "@/components/MainMenu.vue";

import getEnv from "@/utils/getEnv";
import './rupture.styl'
import StockUpdate from "@/components/Stock/StockUpdate.vue";
import App from './App.vue'
import CollectionFilter from "@/components/CollectionFilter.vue";
import StockTransfer from "@/components/StockTransfer.vue";
import ProductImage from "@/components/ProductImage.vue";
import WebCamPhoto from "@/components/WebCamPhoto.vue";
import Focus from '@/directives/focus-directive'
import Quantity from '@/components/Input/Quantity.vue'
import IdleVue from 'idle-vue'
import VueWebCam from 'vue-web-cam'
import Message from "@/components/Message.vue";
import OutboundAddress from "@/components/Outbound/OutboundAddress.vue";
import BulkUpdate from "@/components/Product/BulkUpdate.vue";
import HeaderUser from "@/components/HeaderUser.vue";

Vue.config.productionTip = false;

Vue.component('main-menu', MainMenu);
Vue.component('header-user', HeaderUser);
Vue.component('workspace-selector', WorkspaceSelector);
Vue.component('location-scanner', LocationScanner);
Vue.component('product-scanner', ProductScanner);
Vue.component('packing-material-scanner', PackingMaterialScanner);
Vue.component('text-emphasizes', TextEmphasizes);
Vue.component('stock-update', StockUpdate)
Vue.component('bulk-update', BulkUpdate)
Vue.component('stock-transfer', StockTransfer)
Vue.component('product-image', ProductImage)
Vue.component('input-quantity', Quantity)
Vue.component('message', Message);
Vue.component('outbound-address', OutboundAddress);
Vue.component('web-cam-photo', WebCamPhoto)

Vue.directive('focus', Focus)

Vue.use(VueWebCam)

Vue.use(IdleVue, {
  store,
  idleTime: 900000, // 15 minutes
  startAtIdle: false
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
