import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { Getter } from "vuex-class";
import store from "@/store";

export function hasRole(role: string): boolean {
  let functionPrefix = 'FUNCTION_' + role
  let user = store.getters['oidc/oidcUser'];
  return user && user.roles.indexOf(functionPrefix) !== -1
}

export function getName(): string {
  let user = store.getters['oidc/oidcUser']
  return user.name
}

@Mixin
export class AuthenticationMixin extends Vue {
  @Getter('oidcUser', { namespace: 'oidc' }) user;

  hasRole = hasRole
  getName = getName
}
