















import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class DialogIdle extends Vue {
  @Prop({ 'default': 1000 }) updateInterval: number
  @Prop({ 'default': 60000 }) timeout: number

  timeLeft: number = 0;
  showDialog: boolean = true

  created () {
    this.timeLeft = this.timeout;

    let timerId = setInterval(() => {
      this.timeLeft -= 1000
      if (!this.isAppIdle) clearInterval(timerId)

      if (this.timeLeft < 1) {
        clearInterval(timerId)
        this.$emit('idle-time-up');
      }
    }, this.updateInterval)
  }
}
