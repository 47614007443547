















































































































import Component from 'vue-class-component'
import getEnv from '@/utils/getEnv'
import { DeviceMixin } from '@/mixins/DeviceMixin'
import { AuthenticationMixin } from '@/mixins/AuthenticationMixin'
import { Mixins } from 'vue-mixin-decorator'

interface IMixinInterface extends DeviceMixin, AuthenticationMixin {}

@Component
export default class Dashboard extends  Mixins<IMixinInterface>(DeviceMixin, AuthenticationMixin) {
  get cmsUrl () {
    return getEnv('oauth_authority')
  }
}
