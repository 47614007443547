import axios, {AxiosResponse} from 'axios';
import {Vue} from "vue-property-decorator";
const tokenProvider = require('axios-token-interceptor');

import store from '@/store'

let axiosInstance = axios.create();

let loadingModel:any = undefined;

function closeLoading() {
  if (loadingModel) {
    loadingModel.close();
  }
}

axiosInstance.interceptors.request.use((request) => {
  loadingModel = Vue.prototype.$loading();
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {closeLoading(); return response},
  (error) => {closeLoading(); return Promise.reject(error);}
);


axiosInstance.interceptors.request.use((request) => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let secretKey = urlParams.get('secret_key');
    if (secretKey) {
        return request;
    }
  let token = store.getters['oidc/oidcIdToken'];
  return tokenProvider({
    token: token
  })(request);
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      Vue.prototype.$flashStorage.flash(error.message, 'error', {timeout: 10000});
    } else if (error.response.status >= 500) {
      let message = 'Something went wrong';
      if (error.response.data.message) {
        message = response.response.data.message;
      }
      Vue.prototype.$flashStorage.flash(message, 'error', {timeout: 10000});
    }

    return Promise.reject(error);
  }
);

const extractError = (error: Error) => {
  if (error.response) {
    let response = error.response.data;
    if (response['hydra:description']) {
      return response['hydra:description'];
    }
  }

  return error;
};

export {
  axiosInstance,
  extractError
};
