import { render, staticRenderFns } from "./WebCamPhoto.vue?vue&type=template&id=05dc0734&lang=pug&"
import script from "./WebCamPhoto.vue?vue&type=script&lang=ts&"
export * from "./WebCamPhoto.vue?vue&type=script&lang=ts&"
import style0 from "./WebCamPhoto.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports