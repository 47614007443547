import Vue from 'vue';
import VueFlashMessage from 'vue-flash-message';


Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 10000,
    important: true,
  }
});
require('vue-flash-message/dist/vue-flash-message.min.css');
