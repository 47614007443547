




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import { StockJsonldStockDetail, StockApiFactory } from '@/api';
import {axiosInstance, extractError} from '@/utils/axios';
import {iriToId} from "@/utils/iri";

let stockApi = StockApiFactory(undefined, undefined, axiosInstance)

@Component
export default class StockUpdate extends Vue {
  @Prop({ required: true }) stock: StockJsonldStockDetail
  @Prop({ default: false }) onlyLower: boolean
  @Prop({ default: true }) showQuantity: boolean

  editable: boolean = false
  updatedQty: number = 0
  reason: string|null = null

  cancelChange () {
    this.updatedQty = this.stock.quantity || 0
    this.editable = false
    this.reason = null
  }
  changeStock () {
    if (!this.reason) {
        return
    }
    stockApi.patchStockItem(iriToId(this.stock['@id']), { quantity: this.updatedQty, reason: this.reason })
      .then((result) => {
        this.editable = false
        this.reason = null
        this.stock.quantity = this.updatedQty
        this.$emit('stock-updated')
      }).catch((reason) => { this.flashError(extractError(reason)) })
  }

  @Watch('stock')
  updateStock() {
    this.updatedQty = this.stock.quantity || 0
  }

  get max() {
    if (this.onlyLower) {
      return this.stock.quantity;
    }

    return Infinity
  }

  get qtyDiff() :number {
    return this.updatedQty - (this.stock.quantity || 0)
  }

  created () {
    this.updatedQty = this.stock.quantity || 0
  }



}
