<template>
  <div id="app">
    <div class="header" :class="isMonitor ? 'header--monitor' : ''">
      <main-menu v-if="!isMonitor && isAuthenticated"></main-menu>
      <h1 v-if="isMonitor || isAuthenticated" class="header__title" v-text="$route.meta.pageTitle"></h1>
      <h1 v-else class="header__title" v-text="'Not logged in'"></h1>
      <header-user v-if="isAuthenticated"></header-user>
    </div>
    <flash-message></flash-message>
    <router-view v-show="isMonitor || (isAuthenticated && !shouldEnterPin)"></router-view>
    <DialogPin v-if="!isMonitor && isAuthenticated" @update-should-enter-pin="updateShouldEnterPin"/>
    <DialogIdle v-if="!isMonitor && shouldLogout && isAppIdle" @idle-time-up="$router.push({ 'name': 'logout' })"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogIdle from '@/components/DialogIdle'
import AppStore from "@/store/modules/App";
import {getModule} from "vuex-module-decorators";
import store from "@/store";
import getEnv from "@/utils/getEnv";
import DialogPin from "@/components/DialogPin.vue";
import {isString} from "lodash";

const appStore = getModule(AppStore, store);

export default {
  name: 'app',
  components: {DialogPin, DialogIdle },
  data() {
    return {
        shouldEnterPin: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'oidc/oidcIsAuthenticated',
    }),

    isMonitor() {
        if (!this.$route) {
            return false
        }
        let parts = this.$route.path.split('/')
        return parts[1] === 'monitor';
    },

    shouldLogout: function () {
      return appStore.autoLogout
    }
  },
  methods: {
    updateShouldEnterPin(newValue) {
       this.shouldEnterPin = newValue;
    },
  },
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.header {
  width: 100%;
}
.header h1 {
  display: inline-block;
  font-size: 15px;
}
.header--monitor {
    text-align: center;
}
.header--monitor h1 {
    font-size: 25px;
}
.content {
  margin: 0;
  padding: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15), 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
.content--monitor .el-table {
    font-size: 20px !important;
}
.bold {
  font-weight: bold;
}
.content h2 {
  font-size: 18px;
  margin: 0;
}
.el-dialog {
  width: 90%;
  max-width: 500px;
}
.el-dialog__wrapper.high-visibility-dialog {
    animation: blinkingBackground 2s infinite;
}
.high-visibility-dialog .el-dialog {
    background-color: deepskyblue;
}
.el-dialog__title {
  font-size: 18px;
  font-weight: bold;
}
.high-visibility-dialog .el-dialog__title {
    font-size: 80px;
    color: red;
    text-align: center;
    font-weight: bold;
    display: block;
    padding: 30px;
    border: 10px solid red;
    margin: 25px;
}
.el-dialog__body {
  padding: 10px 20px;
}
.high-visibility-dialog .el-dialog__body {
    font-size: 20px;
}
.el-button {
  border-radius: 0px;
}
.icon-link {
  width: 80px;
  padding: 10px;
  text-align: center;
}
.icon-link a {
  text-decoration: none;
  color: #2c3e50;
}
.icon-link i {
  text-decoration: none;
  color: #2c3e50;
  font-size: 32px;
  display: block;
  margin: 5px 0;
}
.flash__message {
  z-index: 3000;
}
.flash__message >.el-icon-error, .flash__message >.el-icon-success, .flash__message >.el-icon-warning, .flash__message >.el-icon-info {
  margin-right: 5px;
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}

.development-scanner__input {
  width: calc(100% - 150px)
}

.development-scanner__button {
  width: 150px
}
.center {
  text-align: center
}
.stock-search__button {
  position: fixed;
  bottom: 10px;
  left: 20px;
  z-index: 500;
}
.stock-search__dialog .el-dialog {
  width: 100%;
  margin-top: 40px !important;
}
.stock-search__dialog .el-dialog__body {
  padding: 10px 0 0;
}
.stock-transfer__button {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 500;
}
.stock-transfer__dialog .el-dialog {
  width: 100%;
  margin-top: 40px !important;
}
.icon-single-item-list {
    width: 14px;
    height: 14px;
    background-color: white;
    border: 1px solid #606266;
    color: #606266;
    font-size: 10px;
    font-weight: bold;
    border-radius: 3px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@keyframes blinkingBackground{
    0%		{ background-color: #ffffff73 }
    100%	{ background-color: #ff000073 }
}
</style>
