import { DirectiveOptions } from 'vue'

const directive: DirectiveOptions = {
  inserted: function (el) {
    el.focus()
  },
  update(el) {
    el.focus()
  }

}

export default directive
