




import { Component, Vue } from 'vue-property-decorator'
import {getModule} from "vuex-module-decorators";
import AppStore from "@/store/modules/App";
import store from "@/store";

const appStore = getModule(AppStore, store);

@Component({})
export default class HeaderUser extends Vue {
  get initials() {
    return appStore.initials
  }
}
