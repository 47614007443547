export interface BarCodeScannerOptions {
  scannerSensitivity?: number,
  requiredAttr?: boolean,
  controlSequenceKeys?: null|Array<string>
  callbackAfterTimeout?: boolean|Function,
}

export default class BarCodeScanner {
  options: BarCodeScannerOptions = {
    scannerSensitivity: 1000,
    requiredAttr: false,
    // `controlSequenceKeys` should be an array of Strings ([String])
    // that will be joined in a regex string for identifying
    // control sequences
    //
    // they will be replaced in the return string by tags
    // example:
    //   NumLock, 0, 0, 1, 3, NumLock
    //   is replaced with
    //   <VControlSequence>0013</VControlSequence>
    //
    // this allows easy string replacement
    controlSequenceKeys: null,
    // Some scanners do not end their sequence with the ENTER key.
    // This option allows "finishing" the sequence without an ENTER key
    // after the number of ms defined in `setting.scannerSensitivity`
    // elapses after the last character in the sequence.
    // Example:
    // (without timeout, sequence ends with ENTER):
    //   1. Scan barcode
    //   2. Scanner sends sequence of characters to device, ending with ENTER (13) key
    //   3. `callback` passed in `init()` is called
    // (without timeout, sequence ends without ENTER):
    //   1. Scan barcode
    //   2. Scanner sends sequence of characters to device. Final character is not ENTER
    //   3. `callback` is not called until the ENTER key is pressed
    // (with timeout, sequence ends without ENTER):
    //   1. Scan barcode
    //   2. Scanner sends sequence of characters to device. Final character is not ENTER
    //   3. After `setting.scannerSensitivity` ms elapses, `callback` is called
    callbackAfterTimeout: false
  };

  previousCode: string = '';
  barcode: string = '';
  callback: Function|null = null;
  callbackEmptySubmit: Function|null = null;
  hasListener: boolean = false;
  pressedTime: Array<number> = [];
  timeout: number|null = null;
  isInControlSequence: boolean = false;
  isProcessing: boolean = false;

  constructor(options: BarCodeScannerOptions = {}) {
    this.options = {...this.options, ...options};
  }

  init (callback: Function, callbackEmptySubmit: Function|null = null) {
    // add listenter for scanner
    // use keypress to separate lower/upper case character from scanner
    this.addListener('keypress');
    // use keydown only to detect Tab event (Tab cannot be detected using keypress)
    this.addListener('keydown');
    this.callback = callback
    this.callbackEmptySubmit = callbackEmptySubmit
  }

  destroy() {
    this.removeListener('keypress');
    this.removeListener('keydown');
  }

  addListener(type: string) {
    if (this.hasListener) {
      window.removeEventListener(type, this.boundOnInputScanned);
    }
    window.addEventListener(type, this.boundOnInputScanned);
    this.hasListener = true
  }

  removeListener(type: string) {
    window.removeEventListener(type, this.boundOnInputScanned);
  }

  // this is called when either an ENTER key (13) is received
  // or when the `attributes.timeout` fires, following
  // a scan sequence
  finishScanSequence (callCallback: boolean = true) {
    // clear and null the timeout
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = null;

    // scanner is done and trigger Enter/Tab then clear barcode and play the sound if it's set as true
    if (this.callback && callCallback) {
      this.callback(this.barcode);
    }
    // backup the barcode
    this.previousCode = this.barcode;
    // clear textbox
    this.barcode = '';
    // clear pressedTime
    this.pressedTime = [];
    // trigger sound
    this.isProcessing = false
  }

  // if entering a control sequence, add `<VControlSequence>` to the buffer
  // if exiting a control sequence, add `</VControlSequence>` to the buffer
  // toggle control sequence flag
  handleControlBoundaryKeydown() {
    this.barcode += this.isInControlSequence
      ? "</VControlSequence>"
      : "<VControlSequence>";

    this.isInControlSequence = !this.isInControlSequence
  }

  controlSequenceRegex() {
    if (this.options.controlSequenceKeys) {
      return new RegExp((<any>this.options).controlSequenceKeys.join("|"))
    }
    return null
  }

  boundOnInputScanned = this.onInputScanned.bind(this);

  onInputScanned(event: any) {
    const controlRegex = this.controlSequenceRegex();

    if (event.key === 'TVInputHDMI1') {
      return;
    }

    // ignore other keydown event that is not a TAB, so there are no duplicate keys
    if (event.type === 'keydown' && event.keyCode != 9) {
      // Return early if this is not a control key that should be observed
      if (controlRegex && !controlRegex.test(event.key)) return;
      // Return early if no control keys should be observed
      if (!controlRegex) return
    }

    // handle control boundary keydown
    if (event.type === 'keydown' && controlRegex && controlRegex.test(event.key)) {
      return this.handleControlBoundaryKeydown()
    }

    if (this.checkInputElapsedTime(Date.now())) {
      if (!this.isProcessing) {
        this.isProcessing = true
      }

      if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
        if ((event.keyCode === 13 || event.keyCode === 9)) {
          event.target.blur();
          this.finishScanSequence(false);
        }
      } else {
        if ((event.keyCode === 13 || event.keyCode === 9) && this.barcode !== '') {
          this.finishScanSequence();

          // prevent TAB navigation for scanner
          if (event.keyCode === 9) {
            event.preventDefault()
          }
        } else {
          // reset the finish sequence timer and add the key to the buffer
          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          if (this.options.callbackAfterTimeout && this.barcode.length > 0) {
            this.timeout = setTimeout(this.finishScanSequence, this.options.scannerSensitivity);
          }

          if (event.keyCode === 13 && this.barcode === '' && this.callbackEmptySubmit) {
            this.callbackEmptySubmit()
          } else {
            // scan and validate each character
            this.barcode += event.key
          }
        }
      }
    }
  }

  // check whether the keystrokes are considered as scanner or human
  checkInputElapsedTime (timestamp: number) {
    // push current timestamp to the register
    this.pressedTime.push(timestamp);
    // when register is full (ready to compare)
    if (this.pressedTime.length === 2) {
      // compute elapsed time between 2 keystrokes
      let timeElapsed = this.pressedTime[1] - this.pressedTime[0];
      // too slow (assume as human)
      if (timeElapsed >= (this.options.scannerSensitivity || 0)) {
        // put latest key char into barcode
        //this.barcode = event.key;
        // remove(shift) first timestamp in register
        this.pressedTime.shift();
        // not fast enough
        return false
      }
      // fast enough (assume as scanner)
      else {
        // reset the register
        this.pressedTime = []
      }
    }
    // not able to check (register is empty before pushing) or assumed as scanner
    return true
  }
}
