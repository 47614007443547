import {
  InboundInboundCreate,
  InboundItemInboundItemDetail,
  ProductProductC,
  ProductProductDetail,
  ProductProductwrite
} from "@/api";
import {Vue} from "vue/types/vue";

export class ScannedObjectEvent {

  private _object: object;
  private _metadata: object;
  private _target: Vue;

  constructor(
    object: object,
    metadata: object,
    target: Vue
  ) {
    this._object = object;
    this._metadata = metadata;
    this._target = target;
  }

  get object(): object {
    return this._object;
  }

  set object(value: object) {
    this._object = value;
  }

  get metadata(): object {
    return this._metadata;
  }

  get target(): Vue {
    return this._target;
  }

  set target(value: Vue) {
    this._target = value;
  }
}

export class CreateInboundItemEvent {
  private _product: ProductProductwrite;
  private _location: string;
  private _qty: number;
  private _nextProduct: ProductProductDetail|undefined;

  constructor(
    product: ProductProductread,
    location: string,
    qty: number,
    nextProduct?: ProductProductDetail
  ) {
    this._product = product;
    this._location = location;
    this._qty = qty;
    this._nextProduct = nextProduct;
  }

  get product(): ProductProductread {
    return this._product;
  }

  set product(value: ProductProductread) {
    this._product = value;
  }

  get location(): string {
    return this._location;
  }

  set location(value: string) {
    this._location = value;
  }

  get quantity(): number {
    return this._qty;
  }

  set quantity(value: number) {
    this._qty = value;
  }

  get nextProduct(): ProductProductDetail|undefined {
    return this._nextProduct
  }

  set nextProduct(value: ProductProductDetail|undefined) {
    this._nextProduct = value;
  }
}

export interface PatchInboundEvent {
  id: string,
  patch: InboundInboundCreate,
}

export interface PatchInboundStateEvent {
  id: string,
  transition: string
}

export interface PatchInboundItemEvent {
  id: string
  patch: InboundItemInboundItemDetail
}
