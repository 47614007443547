import BarCodeScanner from "@/utils/BarCodeScanner";


export const TYPE_QTY = 'qty';
export const TYPE_EAN = null;
export const TYPE_LOCATION = 'loc';
export const TYPE_CMD = 'cmd';

export class BarCodeEvent {
  private readonly _value: string;
  private readonly _type: string | null;

  constructor(value: string, type: string|null) {
    this._value = value;
    this._type = type;
  }

  get value(): string {
    return this._value;
  }

  get type(): string | null {
    return this._type;
  }
}

export default class CommandBarCodeScanner extends BarCodeScanner {
  init (callback: Function, callbackEmptySubmit: Function|null = null) {
    // add listenter for scanner
    // use keypress to separate lower/upper case character from scanner
    this.addListener('keypress');
    // use keydown only to detect Tab event (Tab cannot be detected using keypress)
    this.addListener('keydown');
    this.callback = this.cmdCallBackFactory(callback)
    this.callbackEmptySubmit = callbackEmptySubmit
    window.scan = function(barcode) {
      this.callback(barcode);
    }.bind(this)
  }

  cmdCallBackFactory(callback: Function) {
    return (barcode: string) => {
      let type = null;
      let value = undefined;

      if (barcode.indexOf(':') === -1) {
        value = barcode;
      } else {
        [type, ...value] = barcode.split(':');
        value = value.join(':');
      }

      let event = new BarCodeEvent(value, type);
      callback(event);
    }
  }
}
