










import { Component, Prop, Vue } from 'vue-property-decorator'
import BarCodeScanner, {BarCodeEvent, TYPE_EAN} from '@/utils/CommandBarCodeScanner'
import {axiosInstance, extractError} from "@/utils/axios";
import { PackingMaterialApiFactory } from "@/api";
import {ScannedObjectEvent} from "@/types/inbound";
import {EnvironmentMixin} from "@/mixins/EnvironmentMixin";
import {Mixins} from "vue-mixin-decorator";

let packingMaterialApi = PackingMaterialApiFactory(undefined, undefined, axiosInstance);

const EXCLUDED_COMBINATIONS = {
  'DHL Parcel': ['99999902', '99999903', '99874568', '99877910'],
  'DHL': ['99999902', '99999903', '99874568', '99877910'],
  'DPD': ['99999903']
}

const EXCLUDED_COUNTRY_COMBINATIONS = {
    'FR': {
        'PostNL': ['99880125']
    }
}

@Component
export default class PackingMaterialScanner extends Mixins<EnvironmentMixin>(EnvironmentMixin) {
    developmentPackingMaterial: string = '';
    error: string|null = null;
    scanner: BarCodeScanner = new BarCodeScanner();
    @Prop({required: false}) shippingMethod: any|null = null

    developmentScan() {
      this.scanItem(new BarCodeEvent(this.developmentPackingMaterial, null));
    }

    scanItem(event: BarCodeEvent) {
        if (event.type !== TYPE_EAN) {
            this.error = 'This is not a packing product';
            return;
        }

        let barcode = event.value;

        let distributor = null
        let countryCode = null
        if (this.shippingMethod) {
          distributor = this.shippingMethod.distributor
          countryCode = this.shippingMethod.countryCode
        }

        if (distributor && EXCLUDED_COMBINATIONS[distributor]) {
          let excludedCodes = EXCLUDED_COMBINATIONS[distributor];
          if (excludedCodes.indexOf(barcode) != -1) {
            this.error = 'Deze verpakking is niet toegestaan voor ' + distributor
            return;
          }
        }

        if (distributor && countryCode && EXCLUDED_COUNTRY_COMBINATIONS[countryCode] && EXCLUDED_COUNTRY_COMBINATIONS[countryCode][distributor]) {
            let excludedCodes = EXCLUDED_COUNTRY_COMBINATIONS[countryCode][distributor];
            if (excludedCodes.indexOf(barcode) != -1) {
                this.error = 'Deze verpakking is niet toegestaan voor ' + distributor + ' ('+countryCode+')'
                return;
            }
        }

        //Todo implement api
        packingMaterialApi.queryPackingMaterialItem(barcode).then((response) => {
            this.error =  null;
            this.$emit('scanned-packing-material', new ScannedObjectEvent(
                response.data,
                this
            ))
        }).catch((reason) => {
            this.error = extractError(reason)
        });
    }

    emptySubmit () {
      this.$emit('packing-material-empty-submit')
    }

    created() {
        this.scanner.init(this.scanItem, this.emptySubmit);
    }

    destroyed() {
        this.scanner.destroy();
    }
}
