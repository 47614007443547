




















import { Component, Vue } from 'vue-property-decorator'
import { find } from 'lodash'

@Component({})
export default class MainMenu extends Vue {
  isCollapsed: boolean = true;
  items = [
    { route: '/', icon: 's-home', label: 'Dashboard' },
    { route: '/inbounds', icon: 'zoom-in', label: 'Inslaan' },
    { route: '/inbound-transports', icon: 'upload2', label: 'Inslag Transport' },
    { route: '/pick-lists', icon: 'download', label: 'Producten Picken' },
    { route: '/to-order-pick', icon: 'download', label: 'Naar Order Pick' },
    { route: '/package-table', icon: 'box', label: 'Orders Verzenden' },
    { route: '/outbounds', icon: 'sold-out', label: 'Uitslagen' },
    { route: '/products', icon: 'document-copy', label: 'Producten' },
    { route: '/locations', icon: 'tickets', label: 'Locaties' },
    { route: '/stocks', icon: 'takeaway-box', label: 'Voorraad' },
    { route: '/disabled-locations', icon: 'video-pause', label: 'Uitgeschakelde Locaties' },
    { route: '/logout', icon: 'switch-button', label: 'Uitloggen' },
  ];

  toggleCollapse () {
    this.isCollapsed = !this.isCollapsed
  }

  closeMenu (event: MouseEvent) {
      if (this.isCollapsed) {
          return
      }
      if (event.target && event.target.classList) {
          if (event.target.classList.contains('main_menu')) {
              return
          }
          if (event.target.classList.contains('main_menu__button')) {
              return
          }
      }
      if (event.target && event.target.parentElement && event.target.parentElement.classList) {
          if (event.target.parentElement.classList.contains('main_menu')) {
              return
          }
          if (event.target.parentElement.classList.contains('main_menu__button')) {
              return
          }
      }
      if (event.target && event.target.innerText) {
          if (event.target.innerText == 'Menu') {
              return
          }
      }
    let mainMenu = find(event.path, (element) => { return element.classList && element.classList.contains('main_menu') })
    if (mainMenu === undefined) {
      this.isCollapsed = true
    }
  }

  mounted () {
    window.addEventListener('click', this.closeMenu)
  }

  destroyed () {
    window.removeEventListener('click', this.closeMenu)
  }
}
