import {VuexModule, Module, Action, Mutation} from "vuex-module-decorators";
import {ProductApiFactory} from "@/api";
import {AxiosResponse} from "axios";
import {axiosInstance} from "@/utils/axios";


let api = ProductApiFactory(undefined, undefined, axiosInstance);

@Module({
  namespaced: true,
  name: 'Product'
})
export default class Product extends VuexModule {
  response: AxiosResponse|null = null;

  get totalItems() {
    if (!this.response) return 0;
    return this.response.data['hydra:totalItems'];
  }

  get members() {
    if (!this.response) return [];

    return this.response.data['hydra:member'];
  }

  @Mutation
  setResponse(response: AxiosResponse) {
    this.response = response
  }

  @Action({commit: 'setResponse'})
  async getList() {
    const response = await api.getProductCollection();
    return response;
  }
}
