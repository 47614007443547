import {VuexModule, Module, Action, Mutation} from "vuex-module-decorators";
import {
  InboundApiFactory, InboundInboundCreate,
  InboundItemApiFactory, InboundItemInboundItemwrite, InboundjsonldInboundread, InlineResponse2001
} from "@/api";
import {axiosInstance} from '@/utils/axios'
import {
  CreateInboundItemEvent,
  PatchInboundEvent,
  PatchInboundItemEvent,
  PatchInboundStateEvent
} from "@/types/inbound";


let inboundApi = InboundApiFactory(undefined, undefined, axiosInstance);
let inboundItemApi = InboundItemApiFactory(undefined, undefined, axiosInstance);

@Module({
  namespaced: true,
  name: 'Inbound'
})
export default class Inbound extends VuexModule {
  private _overview: InlineResponse2001|null = null;
  private _lastInbound: InboundjsonldInboundread|null = null;
  private _inboundItems: Array<any> = [];

  get overview() {
    return this._overview;
  }

  get lastInbound(): InboundjsonldInboundread|null {
    return this._lastInbound;
  }

  @Mutation
  setOverview(overview: InlineResponse2001) {
    this._overview = overview
  }

  @Mutation
  setLastInbound(inbound: InboundjsonldInboundread) {this._lastInbound = inbound}

  @Mutation
  clear()
  {
    this._lastInbound = null;
    this._overview = null;
    this._inboundItems = [];
  }

  @Action({commit: 'setOverview'})
  async getOverview(options) {
    const response = await inboundApi.getInboundCollection(
      'desc',
      undefined,
      options.referenceFilter,
      options.stateFilter,
      undefined,
      options.eanFilter,
      options.dateFilter ? options.dateFilter + ' 23:59:59' : undefined,
      undefined,
      options.dateFilter ? options.dateFilter + ' 00:00:00' : undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      options.preOrderFilter,
      options.page,
      options.itemsPerPage);

    return response.data;
  }

  @Action({commit: 'setLastInbound'})
  async loadInbound(id: string) {
    const response = await inboundApi.getInboundItem(id);
    return response.data;
  }

  @Action
  async deleteInbound(id: string) {
    return await inboundApi.deleteInboundItem(id);
  }

  @Action({commit: 'setLastInbound'})
  async createInbound(reference: string) {
    return await inboundApi.postInboundCollection({reference}).then((response) => {
      return response.data;
    });
  }

  @Action({commit: 'setLastInbound'})
  async patchInbound(event: PatchInboundEvent) {
    const response = await inboundApi.patchInboundItem(event.id, event.patch);
    return response.data;
  }

  @Action({commit: 'setLastInbound'})
  async patchInboundState(event: PatchInboundStateEvent) {
    const response = await inboundApi.statePatchInboundItem(
      event.id,
      {
        transition: event.transition
      });

    return response.data;
  }

  @Action
  async addInboundItem(event: CreateInboundItemEvent) {
   return await inboundItemApi.postInboundItemCollection(<InboundItemInboundItemwrite> {
      quantity: parseInt(event.quantity),
      location: event.location['@id'],
      product: event.product['@id'],
      inbound: this.lastInbound['@id']
    }).then((response) => {return response});
  }

  @Action
  async deleteInboundItem(id) {
    return await inboundItemApi.deleteInboundItemItem(id);
  }

  @Action
  async patchInboundItem(event: PatchInboundItemEvent) {
    return await inboundItemApi.patchInboundItemItem(event.id, event.patch)
  }
}
