

























import {Component, Prop, Vue} from 'vue-property-decorator'
import {axiosInstance} from '@/utils/axios'
import {OutboundAddressApiFactory, OutboundAddressOutboundAddressDetail, OutboundOutboundDetail} from '@/api';
import {IriMixin} from "@/mixins/IriMixin";
import {Mixins} from 'vue-mixin-decorator'
import {extractError} from "@/utils/axios";

const DIGICODE_COUNTRIES = ['FR']
const outboundAddressApi = OutboundAddressApiFactory(undefined, undefined, axiosInstance)

interface IMixinInterface extends IriMixin {}

@Component({})
export default class OutboundAddress extends Mixins<IMixinInterface>(IriMixin) {
  @Prop({required: true}) outbound: OutboundOutboundDetail;
  address: OutboundAddressOutboundAddressDetail|null = null;
  error: string|null = null

  get showDigicode() {
    return this.address && DIGICODE_COUNTRIES.includes(this.address.countryCode)
  }

  update() {
    let data = Object.assign({}, this.address)
    delete data['@context']
    delete data['@id']
    delete data['@type']
    delete data['countryCode']

    outboundAddressApi.patchOutboundAddressItem(
      this.iriToId(this.outbound['@id']),
      data
    ).then((response) => {
      this.address = response.data
      this.error = null

      this.flashSuccess('Verzendadres gewijzigd')
      this.$emit('updated');
    }).catch((error) => {
      this.error = extractError(error)
    })
  }

  created() {
    outboundAddressApi.getOutboundAddressItem(
      this.iriToId(this.outbound['@id'])
    ).then((response) => {
      this.address = response.data
      this.error = null
    }).catch((error) => {
      this.address = null
      this.error = extractError(error)
    })
  }
}
