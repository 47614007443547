



























  import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {BarCodeEvent} from '@/utils/CommandBarCodeScanner'

@Component({})
export default class Quantity extends Vue {
  @Prop({required: true}) quantity: number
  @Prop({default: 'Aantal'}) label: string|null
  @Prop({default: false}) locationScanner: boolean
  @Prop({default: false}) productScanner: boolean
  @Prop({default: 0}) min: number|null
  @Prop({default: null}) max: number|null
  @Prop({default: false}) focus: boolean
  @Prop({default: false}) button: boolean

  qty: number = 0

  get decreaseClass() {
    if (!this.canDecrease) {
      return 'is-disabled'
    }
    return ''
  }

  @Emit('scanned-location')
  scannedLocation(event) {
    return event;
  }

  @Emit('scanned-product')
  scannedProduct(event) {
    return event;
  }

  setQty(qty) {
      this.qty = qty
  }

  get increaseClass() {
    if (!this.canIncrease) {
      return 'is-disabled'
    }
    return ''
  }

  get canDecrease() {
    if (this.min && this.qty <= this.min) {
      return false
    }
    return true
  }

  get canIncrease() {
    if (this.max && this.qty >= this.max) {
      return false
    }
    return true
  }

  decreaseQuantity() {
    if (this.canDecrease) {
      this.qty--
      this.quantityChanged(this.qty)
    }
  }

  increaseQuantity() {
    if (this.canIncrease) {
      this.qty++
      this.quantityChanged(this.qty)
    }
  }

  updateQuantity() {
      this.$emit('qty-updated', this.qty)
  }

  quantityChanged(event) {
    let quantity = this.qty
    if (event) {
        quantity = parseInt(event)
    }

    if (this.min && quantity < this.min) {
      quantity = this.min
    }
    if (this.max && quantity > this.max) {
      quantity = this.max
    }

    this.qty = quantity
    if (!this.button) {
        this.$emit('qty-updated', quantity)
    }

    if (typeof event === 'string') {
      let location = event.substring(this.qty.toString().length)
      if (event.includes('loc:')) {
        let parts = event.split('loc:')
        let location = parts[1]
        if (this.$refs['quantity-location-scanner']) {
            this.$refs['quantity-location-scanner'].scanItem(new BarCodeEvent(location, 'loc'))
        }
      } else if (location > 0 && location.length > 12)  {
        if (this.$refs['productScanner']) {
            this.$refs['productScanner'].scanItem(new BarCodeEvent(location))
        }
      }
    }
  }

  created() {
    this.qty = this.quantity
  }

  mounted() {
    if (this.focus) {
      if (this.$refs['input-quantity']) {
        this.$refs['input-quantity'].focus()
      }
    }
  }
}
