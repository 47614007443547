import Vue from 'vue'
import {
  Button,
  Upload,
  Checkbox,
  Dialog,
  DatePicker,
  TimeSelect,
  Input,
  InputNumber,
  Menu,
  MenuItem,
  Pagination,
  Row,
  Col,
  Table,
  TableColumn,
  Loading,
  Option,
  Progress,
  Select,
  Steps,
  Step,
  Switch} from 'element-ui'

import TextEmphasizes from '@/components/TextEmphasizes'

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
locale.use(lang)

Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Option)
Vue.use(Select)
Vue.use(Row)
Vue.use(Col)
Vue.component(TextEmphasizes)
Vue.use(Steps)
Vue.use(Step)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(Switch)
Vue.use(Progress)
Vue.use(Upload)
