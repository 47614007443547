





























import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {LocationLocationDetail, StockApiFactory} from '@/api';
import {axiosInstance, extractError} from '@/utils/axios';
import {iriToId} from "@/utils/iri";

let stockApi = StockApiFactory(undefined, undefined, axiosInstance)

@Component
export default class StockTransfer extends Vue {
  @Prop({ required: true }) stock: StockjsonldStockDetail
  showScanPopup: boolean = false
  transferQty: number = 1

  transferStock () {
    this.showScanPopup = true
  }

  @Watch('stock')
  stockUpdated() {
    this.transferQty = this.stock.quantity
  }

  created() {
    this.transferQty = this.stock.quantity;
  }

  selectLocation(event) {
    let scannedLocation = <LocationLocationDetail>event.object
    stockApi.transferStockItem(iriToId(this.stock['@id']), {
        quantity: this.transferQty,
        toLocation: scannedLocation['@id']
      }).then((result) => {
        this.showScanPopup = false
        this.$emit('stock-transfered')
      }).catch((reason) => {
        this.flashError(extractError(reason))
      })
  }
}
