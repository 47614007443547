











import {Component, Vue} from 'vue-property-decorator'
import VueWebCam from "vue-web-cam";

@Component
export default class WebCamPhoto extends Vue {
    capturedImages = []

    onCapture() {
        let image = this.$refs.webcam.capture()
        this.capturedImages.push(image)
        this.$emit('captureImagesChangedAction', this.capturedImages)
    }
}
