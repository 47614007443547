



import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import {mapActions} from "vuex";
import {getModule} from "vuex-module-decorators";
import AppStore from "@/store/modules/App";
import store from "@/store";

const appStore = getModule(AppStore, store)

@Component({
  methods:{
    ...mapActions({
      logOut: 'oidc/signOutOidc',
    })
  }
})
export default class Logout extends Vue {
  created() {
    appStore.setIdentifiedAt(null)
    this.logOut()
  }
}
