import Vue from 'vue'
import Vuex from 'vuex'
import App from "@/store/modules/App";
import Product from "@/store/modules/Product";
import Inbound from "@/store/modules/Inbound";

import { vuexOidcCreateStoreModule } from "vuex-oidc";
import VuexPersistence from "vuex-persist";
import getEnv from "@/utils/getEnv";
import { pickBy } from 'lodash';
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

export const vuexSession = new VuexPersistence({
  strictMode: false,
  storage: window.sessionStorage,
  key: 'etrias-warehouse',
  reducer: (state) => {
    return { App: pickBy(state.App, (value, key) => key !== '_workspace' && key !== '_identifiedAt' && key !== '_shippedCount' && key !== '_copyData' && key !== '_userName' && key !== '_packingZone' && key !== '_packingLocation')}
  }
});

export const vuexLocal = new VuexPersistence({
  strictMode: false,
  storage: window.localStorage,
  key: 'etrias-warehouse',
  reducer: (state) => {
    return { App: pickBy(state.App, (value, key) => key === '_workspace' || key === '_identifiedAt' || key === '_shippedCount' || key === '_copyData' || key === '_userName' || key === '_packingZone' || key === '_packingLocation')}
  }});

const notSyncableMutations = [
    'FlashMessages/clear',
]

export default new Vuex.Store({
  state: {},
  actions: {},
  modules: {
    App,
    Product,
    Inbound,
    oidc: vuexOidcCreateStoreModule(
      {
        authority: getEnv('oauth_authority'),
        clientId: getEnv('oauth_client_id'),
        loadUserInfo: false,
        redirectUri: location.protocol.concat("//").concat(window.location.host).concat('/oidc-callback'),
        silentRedirectUri: location.protocol.concat("//").concat(window.location.host).concat('/oidc-silent-renew'),
        automaticSilentRenew: true,
        responseType: 'code',
        scope: 'openid profile warehouse'
      },{
        namespaced: true,
        dispatchEventsOnWindow: true,
      }
    )
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
    RESTORE_SESSION_MUTATION: vuexSession.RESTORE_MUTATION
  },
  plugins: [
      vuexLocal.plugin,
      vuexSession.plugin,
      createMutationsSharer({
          predicate: (mutation, state) => {
              return !notSyncableMutations.includes(mutation.type);
          },
      })
  ]
})
