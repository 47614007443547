




  import {Component, Prop, Vue} from 'vue-property-decorator'
  import {Product} from "@/interfaces/product";

@Component
export default class ProductImage extends Vue {
  @Prop({required: true}) product: Product;
  @Prop({default: 150}) size: number;

  created() {
    if (!this.product.imageUrl) {
      this.$destroy()
    }
  }
}
