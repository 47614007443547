















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {ProductJsonldProductDetail, ProductApiFactory, StockApiFactory} from '@/api';
import {axiosInstance, extractError} from '@/utils/axios';
import {iriToId} from "@/utils/iri";
import { hasRole } from "@/mixins/AuthenticationMixin";

let productApi = ProductApiFactory(undefined, undefined, axiosInstance)
let stockApi = StockApiFactory(undefined, undefined, axiosInstance)

@Component
export default class BulkUpdate extends Vue {
    @Prop({required: true}) product: ProductJsonldProductDetail
    @Prop({required: false, default: false}) bulkLocation: boolean

    hasRole = hasRole

    editable: boolean = false
    desiredPickQty: number = 0
    pickQtyThreshold: number = 0
    hasBulkStock: boolean = false

    get maxThresholdQty(): number {
        return Math.max(0, this.desiredPickQty - 1)
    }

    get minimumQty(): number {
        if (this.bulkLocation) {
            return 1
        }
        if (this.hasBulkStock) {
            return 1
        }
        return 0
    }

  cancelChange() {
      this.desiredPickQty = this.product.desiredPickQty
      this.pickQtyThreshold = this.product.pickQtyThreshold
      this.editable = false
  }
  changeBulk() {
    if ((this.desiredPickQty > 0 && this.pickQtyThreshold === 0) || (this.pickQtyThreshold > 0 && this.desiredPickQty === 0)) {
       this.flashError('Niet toegestaan, beide waardes moeten positief zijn')
       return
    }
    productApi.patchProductItem(iriToId(this.product['@id']), {
        desiredPickQty: this.desiredPickQty,
        pickQtyThreshold: this.pickQtyThreshold
    }).then((result) => {
        this.editable = false
        this.$emit('bulk-updated')
      }).catch((reason) => { this.flashError(extractError(reason)) })
  }

  @Watch('stock')
  updateStock() {
    this.desiredPickQty = this.product.desiredPickQty
    this.pickQtyThreshold = this.product.pickQtyThreshold
  }

  @Watch('product')
  updateProduct() {
     this.desiredPickQty = this.product.desiredPickQty
     this.pickQtyThreshold = this.product.pickQtyThreshold
  }

  created() {
    this.desiredPickQty = this.product.desiredPickQty
    this.pickQtyThreshold = this.product.pickQtyThreshold
    if (!this.bulkLocation) {
      stockApi.getStockCollection(undefined, this.product.ean, undefined, undefined, undefined, undefined, undefined, undefined, undefined, ['bulk'])
          .then(response => {
              let stocks = response.data['hydra:member']
              if (stocks.length > 0) {
                  this.hasBulkStock = true
              }
          })
    }
  }
}
