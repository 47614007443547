import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';

@Mixin
export class DeviceMixin extends Vue {

  get isMobile () {
    return screen.width <= 768
  }
}
