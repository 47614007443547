
import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';

@Mixin
export class EnvironmentMixin extends Vue {
  get isProduction(): boolean
  {
    if (!process) {
      return true;
    }

    return process.env.NODE_ENV !== 'development'
  }
}
